import React, { useState, useRef, useCallback, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Flex, Text } from '@chakra-ui/react';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';

import api from '~/services/api';
import convertBRL from '~/utils/convertBRL';
import internalServerError from '~/utils/internalServerError';
import genStatus, { statusText } from '~/utils/genStatus';
import { getUser } from '~/services/auth';
import { getParam } from '~/utils/url';

import HistoryModal from './HistoryModal';
import EditVacancyModal from './Edit';
import CandidaturesModal from './CandidaturesModal';
import DeleteVacancyModal from './DeleteVacancyModal';

import IsAllowed from '~/components/UI/IsAllowed';
import InputArea from '~/components/Dashboard/Inputs/InputArea';
import IconStatus from '~/components/UI/Cards/VacancyCard/IconStatus';

import { PendingDoctorUrl } from '~/components/UI/Sections';
import { Modal, ModalContainer } from '~/components/UI/Modal';
import { Button, Spinner, Avatar } from '~/components/ChakraComponents';

export default function ChooseOption({
  vacancy,
  scheduleId,
  resetVacancies,
  closeModal,
}) {
  const user = getUser();
  const { hospitalId } = getParam('hospitalId');

  const isNotOldVacancy = new Date(vacancy.endHour) > new Date();

  const isPossibleConfirm =
    vacancy.status === 'pending' &&
    (vacancy.companyDoctor || vacancy.pendingDoctorCrm);

  // const isPossibleConfirm =
  //   isNotOldVacancy &&
  //   vacancy.status === 'pending' &&
  //   (vacancy.companyDoctor || vacancy.pendingDoctorCrm);

  const [loading, setLoading] = useState(true);
  const [loadingObs, setLoadingObs] = useState(true);

  const [newJustification, setNewJustification] = useState();
  const [openJustification, setOpenJustification] = useState(false);
  const [vacancyJustification, setVacancyJustification] = useState();

  const [obs, setObs] = useState([]);
  const [editObsId, setEditObsId] = useState();
  const [editObsText, setEditObsText] = useState();
  const [openCreateObs, setOpenCreateObs] = useState(false);
  const [newObsText, setNewObsText] = useState();

  const toggleJustification = () => setOpenJustification(!openJustification);
  const toggleCreateObjs = () => setOpenCreateObs(!openCreateObs);

  const historyModalRef = useRef(null);
  const editVacancyModalRef = useRef(null);
  const candidaturesModalRef = useRef(null);
  const deleteVacancyModalRef = useRef(null);

  const openHistory = useCallback(() => {
    historyModalRef.current.openModal();
  }, []);

  const openEditVacancy = useCallback(() => {
    editVacancyModalRef.current.openModal();
  }, []);

  const openCandidatures = useCallback(() => {
    candidaturesModalRef.current.openModal();
  }, []);

  const openDeleteVacancy = useCallback(() => {
    deleteVacancyModalRef.current.openModal();
  }, []);

  const fetchJustification = () => {
    setLoading(true);

    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/0`;

    api.get(url).then(response => {
      const justification = response.data.companyJustification || 'Nenhuma';

      setNewJustification(justification);
      setVacancyJustification(justification);

      setLoading(false);
    });
  };

  const fetchObservations = () => {
    setLoadingObs(true);

    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/observations?order=DESC`;

    api.get(url).then(response => {
      setObs(response.data.data);
      setLoadingObs(false);
    });
  };

  useEffect(() => {
    fetchJustification();
    fetchObservations();
  }, []);

  const justify = () => {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/justify`;

    api
      .put(url, { justification: newJustification })
      .then(() => {
        toast.success('Justificativa editada com sucesso!');
        fetchJustification();
        toggleJustification();
      })
      .catch(internalServerError);
  };

  const deleteObservation = observationId => {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/observations/${observationId}`;

    api.delete(url).then(() => {
      toast.success('Observação deletada com sucesso!');
      fetchObservations();
      setEditObsId(null);
    });
  };

  const editObservation = () => {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/observations/${editObsId}`;

    api.patch(url, { observation: editObsText }).then(() => {
      toast.success('Observação editada com sucesso!');
      fetchObservations();
      setEditObsId(null);
    });
  };

  const createObservation = () => {
    const url = `/companies/myhospitals/${hospitalId}/schedules/${scheduleId}/vacancies/${vacancy.id}/observations`;

    api.post(url, { observation: newObsText }).then(() => {
      toast.success('Observação criada com sucesso!');
      fetchObservations();
      toggleCreateObjs();
    });
  };

  return (
    <ModalContainer w="500px" h="auto" loading={loading}>
      <Flex w="100%" mb={4} flexDir="column">
        <Flex
          w="100%"
          mb={2}
          justifyContent="space-between"
          fontSize="lg"
          fontWeight="semibold"
        >
          <Text color="var(--primary)">
            {moment(new Date(vacancy.startHour)).format('DD/MM/YYYY')}
          </Text>
          <Text color="var(--secondary)">
            {convertBRL(vacancy.value)} ({vacancy.inCash ? 'à Vista' : 'Normal'}
            )
          </Text>
        </Flex>
        {vacancy.pendingDoctorCrm && (
          <>
            <Flex
              mb={4}
              flexDir="column"
              alignItems="center"
              textAlign="center"
              fontSize="md"
              fontWeight="semibold"
            >
              <Text color="gray.600">Médico Pendente</Text>
              <Text fontWeight="bold" color="gray.600">
                {vacancy.pendingDoctorName}
              </Text>
              <Text color="gray.500">
                {vacancy.pendingDoctorCrm}-{vacancy.pendingDoctorCrmUf}
              </Text>
            </Flex>
            <PendingDoctorUrl hash={vacancy.pendingDoctorHash} />
          </>
        )}
        {vacancy.companyDoctor && (
          <>
            <Flex flexDir="column" alignItems="center" textAlign="center">
              <Avatar
                w="70px"
                h="70px"
                name={vacancy.doctor.user.name}
                src={vacancy.doctor.user.avatar}
              />
              <Text mt={3} fontSize="md" fontWeight="bold" color="gray.600">
                {vacancy.doctor.user.name}
              </Text>
              <Text fontSize="md" fontWeight="semibold" color="gray.500">
                {`${vacancy.doctor.crm}-${vacancy.doctor.crmUf}`}
              </Text>
            </Flex>
            <Flex mt={4} mb={2} alignItems="center">
              <IconStatus vacancy={vacancy} />
              <Text ml={3} fontWeight="semibold" fontSize="md" color="gray.600">
                {statusText(genStatus(vacancy))}
              </Text>
            </Flex>
            {['accepted', 'pending', 'expired', 'checkin', 'checkout'].includes(
              genStatus(vacancy)
            ) && (
              <Flex flexDir="column" color="gray.600" fontWeight="semibold">
                <Text>{`Checkin: ${
                  vacancy.checkin ? 'Realizado' : 'Não realizado'
                } ${
                  vacancy.checkin
                    ? `às ${moment(new Date(vacancy.checkinDate)).format(
                        'HH:mm'
                      )}h`
                    : ''
                }`}</Text>
                <Text>{`Checkout: ${
                  vacancy.checkout ? 'Realizado' : 'Não realizado'
                } ${
                  vacancy.checkout
                    ? `às ${moment(new Date(vacancy.checkoutDate)).format(
                        'HH:mm'
                      )}h`
                    : ''
                }`}</Text>
              </Flex>
            )}
          </>
        )}
        {!vacancy.companyDoctor && !vacancy.pendingDoctorCrm && (
          <Flex w="100%" justifyContent="center" textAlign="center">
            {isNotOldVacancy ? (
              <Text color="var(--green)" fontWeight="semibold" fontSize="lg">
                Vaga aberta.
              </Text>
            ) : (
              <Text color="var(--red)" fontWeight="semibold" fontSize="lg">
                Vaga aberta com falta.
              </Text>
            )}
          </Flex>
        )}
      </Flex>
      <IsAllowed id={8}>
        <Flex justifyContent="center">
          <Button
            width="110px"
            height="40px"
            title="histórico"
            mb={2}
            onClick={openHistory}
          />
        </Flex>
      </IsAllowed>
      <Flex justifyContent="center">
        {isPossibleConfirm && (
          <Button
            width="140px"
            height="40px"
            title="Confirmar Plantão"
            mx={2}
            onClick={() => {
              api.put(`/companies/myvacancy/${vacancy.id}`).then(() => {
                toast.success('Plantão Confirmado com Sucesso!');
                setTimeout(() => window.location.reload(), 1200);
              });
            }}
          />
        )}
      </Flex>

      <IsAllowed id={8}>
        <Flex justifyContent="center">
          <Button
            width="140px"
            height="40px"
            title="editar vaga"
            mx={2}
            onClick={openEditVacancy}
          />
          {!vacancy.companyDoctor && (
            <Button
              width="140px"
              height="40px"
              title={`${vacancy.countCandidature} candidatura(s)`}
              mx={2}
              onClick={openCandidatures}
            />
          )}

          <Button
            title="excluír plantão"
            width="140px"
            height="40px"
            variant="danger"
            mx={2}
            onClick={openDeleteVacancy}
          />
        </Flex>
      </IsAllowed>
      {!isNotOldVacancy && (
        <Flex w="100%" flexDir="column">
          <Flex
            alignItems="center"
            color="var(--red)"
            fontWeight="bold"
            textTransform="uppercase"
          >
            <Text>Justificativa</Text>
            <IsAllowed id={newJustification === 'Nenhuma' ? 12 : 13}>
              <Text
                onClick={toggleJustification}
                cursor="pointer"
                ml={2}
                pb="3px"
                fontSize="lg"
                _hover={{ color: 'var(--hover-red)' }}
              >
                <FaEdit />
              </Text>
            </IsAllowed>
          </Flex>
          {openJustification ? (
            <>
              <InputArea
                type="text"
                placeholder="Informe a nova justificativa..."
                rows="8"
                value={newJustification}
                onChange={e => setNewJustification(e.target.value)}
              />
              <Flex mt={4} justifyContent="space-between">
                <Button
                  title="cancelar"
                  width="110px"
                  height="40px"
                  variant="secondary"
                  onClick={toggleJustification}
                />
                <Button
                  title="atualizar"
                  width="110px"
                  height="40px"
                  variant="success"
                  onClick={justify}
                />
              </Flex>
            </>
          ) : (
            <Text>{vacancyJustification}</Text>
          )}
        </Flex>
      )}
      <Flex w="100%" mt={4} flexDir="column">
        <Flex
          alignItems="center"
          color="var(--primary)"
          fontWeight="bold"
          textTransform="uppercase"
        >
          <Text>Observações</Text>
          <IsAllowed id={8}>
            <Text
              onClick={toggleCreateObjs}
              cursor="pointer"
              ml={2}
              fontSize="lg"
              _hover={{ color: 'var(--hover-primary)' }}
            >
              <FaPlusCircle />
            </Text>
          </IsAllowed>
        </Flex>
        {openCreateObs && (
          <Flex mt={3} flexDir="column">
            <InputArea
              type="text"
              placeholder="Criar observação..."
              rows="6"
              value={newObsText}
              onChange={e => setNewObsText(e.target.value)}
            />
            <Flex mt={4} justifyContent="space-between">
              <Button
                title="cancelar"
                width="110px"
                height="40px"
                variant="secondary"
                onClick={toggleCreateObjs}
              />
              <Button
                title="criar"
                width="110px"
                height="40px"
                variant="success"
                onClick={createObservation}
              />
            </Flex>
          </Flex>
        )}
        {loadingObs ? (
          <Spinner />
        ) : (
          <Flex flexDir="column">
            {obs.map(observation => (
              <Flex
                key={observation.id}
                w="100%"
                p={2}
                mt={2}
                borderRadius="4px"
                backgroundColor="white"
              >
                <Flex w="100%" flexDir="column">
                  {editObsId === observation.id ? (
                    <Flex flexDir="column" pr={4}>
                      <InputArea
                        type="text"
                        placeholder="Editar justificativa..."
                        rows="6"
                        value={editObsText}
                        onChange={e => setEditObsText(e.target.value)}
                      />
                      <Flex mt={4} justifyContent="space-between">
                        <Button
                          title="cancelar"
                          width="110px"
                          height="40px"
                          variant="secondary"
                          onClick={() => setEditObsId(null)}
                        />
                        <Button
                          title="editar"
                          width="110px"
                          height="40px"
                          variant="success"
                          onClick={editObservation}
                        />
                      </Flex>
                    </Flex>
                  ) : (
                    <>
                      <Flex alignItems="center">
                        <Avatar
                          mr={2}
                          name={observation.usercompany.user.name}
                          src={observation.usercompany.user.avatar}
                        />
                        <Text>{observation.usercompany.user.name}</Text>
                      </Flex>
                      <Text mt={5} mb={2}>
                        {observation.observation}
                      </Text>
                      <Text color="var(--primary)" fontWeight="semibold">
                        {moment(new Date(observation.createdAt)).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </Text>
                    </>
                  )}
                </Flex>
                <IsAllowed id={8}>
                  <Flex
                    w="20px"
                    flexDir="column"
                    flexShrink="none"
                    alignItems="center"
                    justifyContent="space-between"
                    fontSize="xl"
                  >
                    {observation.usercompany.user.id === user.id && (
                      <>
                        <FaEdit
                          color="var(--primary)"
                          onClick={() => {
                            setEditObsId(observation.id);
                            setEditObsText(observation.observation);
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                        <FaTrash
                          color="var(--red)"
                          onClick={() => deleteObservation(observation.id)}
                          style={{ cursor: 'pointer' }}
                        />
                      </>
                    )}
                  </Flex>
                </IsAllowed>
              </Flex>
            ))}
          </Flex>
        )}
        <Flex
          w="100%"
          mb={2}
          mt={4}
          justifyContent="space-between"
          fontSize="lg"
          fontWeight="semibold"
        >
          <Text color="var(--gray)" fontSize={12} alignSelf="flex-end">
            Criado em:{' '}
            {new Intl.DateTimeFormat('pt-BR', {
              timeZone: 'America/Sao_Paulo',
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false, // Formato 24 horas
            }).format(new Date(vacancy.createdAt))}
          </Text>
          <Text color="var(--gray)">#{vacancy.id}</Text>
        </Flex>
      </Flex>
      <Modal
        title="Editar Vaga"
        ref={editVacancyModalRef}
        component={EditVacancyModal}
        vacancy={vacancy}
        scheduleId={scheduleId}
        closeModalCallback={() => {
          closeModal();
          resetVacancies();
        }}
      />
      <Modal
        title="Candidaturas"
        ref={candidaturesModalRef}
        component={CandidaturesModal}
        vacancy={vacancy}
      />
      <Modal
        title="Histórico"
        ref={historyModalRef}
        component={HistoryModal}
        vacancy={vacancy}
        scheduleId={scheduleId}
      />
      <Modal
        title="Excluír Plantão"
        ref={deleteVacancyModalRef}
        component={DeleteVacancyModal}
        vacancy={vacancy}
      />
    </ModalContainer>
  );
}
