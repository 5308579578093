import React, { useState, useEffect, useRef } from 'react';
import { Flex, Spinner, Select } from '@chakra-ui/react';

import api from '~/services/api';
import { getParam } from '~/utils/url';
import { toast } from 'react-toastify';

import { Button } from '~/components/ChakraComponents';
import { Modal } from '~/components/UI/Modal';
import SetupModal from '../../DesktopAgenda/WeekSectorCard/CreateVacancyModal/SetupModal';

export default function SelectContract() {
  const { hospitalId } = getParam('hospitalId');
  const [contracts, setContracts] = useState([]);
  const [selectedContract, setSelectedContract] = useState(null);
  const [loadingContracts, setLoadingContracts] = useState(true);

  const setupModalRef = useRef(null);

  // Fetch contracts on component mount
  useEffect(() => {
    const url = `/companies/myhospitals/${hospitalId}/contracts/`;

    api
      .get(url)
      .then(response => {
        const activeContracts = response.data.data.filter(
          contract => contract.schedule && contract.isActive
        );

        // Sort activeContracts alphabetically based on the formatted string
        const sortedContracts = activeContracts.sort((a, b) => {
          const aString = `${a.sector} - ${a.start.slice(
            0,
            -3
          )}h às ${a.end.slice(0, -3)}h`;
          const bString = `${b.sector} - ${b.start.slice(
            0,
            -3
          )}h às ${b.end.slice(0, -3)}h`;
          return aString.localeCompare(bString);
        });

        setContracts(sortedContracts);
        setLoadingContracts(false);
      })
      .catch(() => {
        setContracts([]);
        setLoadingContracts(false);
      });
  }, [hospitalId]);

  const openSetupModal = () => {
    if (!selectedContract) {
      toast.error('Por favor, selecione um contrato.');
      return;
    }
    setupModalRef.current.openModal();
  };

  return (
    <Flex w="100%" h="100%" flexDir="column" alignItems="center">
      {loadingContracts ? (
        <Spinner />
      ) : (
        <Select
          placeholder="Selecione um contrato"
          onChange={e => {
            const contractId = e.target.value;
            const contract = contracts.find(c => c.id === Number(contractId));
            setSelectedContract(contract);
          }}
        >
          {contracts.map(contract => (
            <option key={contract.id} value={contract.id}>
              {`${contract.sector} - ${contract.start.slice(
                0,
                -3
              )}h às ${contract.end.slice(0, -3)}h`}
            </option>
          ))}
        </Select>
      )}
      {selectedContract && (
        <Button
          title="Criar vaga aberta"
          variant="primary"
          onClick={openSetupModal}
          mt={4}
        />
      )}

      <Modal
        title="Criar Vaga"
        ref={setupModalRef}
        component={SetupModal}
        schedule={selectedContract?.schedule}
        companyDoctor={null}
      />
    </Flex>
  );
}
